@import "/node_modules/bootstrap/dist/css/bootstrap.css"; /* Importing Bootstrap */

/* --- Home --- */

/* Layout */

.main {
    margin-top: 30px;
}

.main, .flex-column {
    display: flex;
    justify-content: center;
    width: 100%;
}

.line {
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 1250px) {
    .line {
        flex-direction: column;
    }
}

.panel {
    width: 100%;
    padding: 25px;
}

.frame {
    width: 100%;
    min-width: 400px;
    height: 365px;
    border: 2px solid #e1e1e1;
    border-radius: 5px;
}

.example {
    text-align: center;
    border-bottom: 1px solid #e1e1e1;
}

.left-panel .frame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 360px;
    background-color: #000;
    overflow: hidden;
}

#instructions {
    display: flex;
    position: absolute;
    align-items: flex-start;
    height: 360px;
    padding: 10px;
}

#search-results {
    display: flex;
    flex-direction: column;
    height: 360px;
}

#found-sign {
    display: none;
    justify-content: center;
    align-items: flex-end;
    height: 50%;
    overflow: hidden;
}

/* Text */

.language-title {
    font-size: 16pt;
    color: #53585c;
}

.word {
    display: block;
    padding: 10px;
    cursor: pointer;
}

#waiting-translation-message {
    display: block;
    padding: 10px;
    font-size: 16pt;
    color: #cbcbcb;
}

.frequency-1 {
    font-size: 16pt;
}

.frequency-2 {
    font-size: 14pt;
}

.frequency-3 {
    font-size: 13pt;
}

.frequency-4 {
    font-size: 12pt;
}

.highlight {
    background-color: #01b1af;
}

#instructions span {
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 5px;
    border-radius: 5px;
}

/* Links */
.navbar a {
    font-size: 16pt;
    color: #01b1af;
    text-decoration: none;
}

/* Loaders */

#camera-loader {
    display: flex;
    position: absolute;
    align-items: center;
    color: #FFF;
}

#camera-loader > * {
    padding: 5px;
}

#sign-search-loader {
    display: none;
    position: absolute;
    align-items: center;
    padding: 5px;
}

#video-recording-countdown {
    display: none;
    position: absolute;
    align-items: center;
    width: 40px;
    height: 40px;
}

#video-recording-countdown-number {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #FFF;
}

#video-recording-countdown-icon {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    transform: rotateY(-180deg) rotateZ(-90deg);
}

#video-recording-countdown-icon {
    stroke-dasharray: 113px;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: 5px;
    stroke: white;
    fill: none;
}

@keyframes countdown {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 113px;
    }
}

#translation-search-loader {
    display: none;
    position: absolute;
    align-items: center;
    padding: 15px;
}

#example-loader {
    display: none;
    width: 100%;
    text-align: center;
}

/* Icons */

@media screen and (max-width: 1250px) {
    #translation-direction-icon {
        transform: rotate(90deg);
    }
}

/* Buttons */

#reset-button {
    display: none;
    position: absolute;
    z-index: 1;
    padding: 10px;
    color: #53585c;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 100%;
}

#ok-button, #ko-button {
    display: none;
    position: absolute;
    width: 48px;
    z-index: 1;
    margin: 20px;
    padding: 10px;
    color: #53585c;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 100%;
}

#ok-button:active, #ko-button:active, #reset-button:active {
    box-shadow: inset 0 0 5px #373737;
}

#ok-button {
    margin-right: 75%;
}

#ko-button {
    margin-left: 75%;
}

/* Media */

.video-input {
    display: none;
}

#recorded-video {
    display: none;
    height: 50%;
    justify-content: center;
    overflow: hidden;
}

#recorded-video video {
    height: 100%;
}

#found-sign #found-sign-gif {
    height: 100%;
}

.video {
    width: 75%;
}

@media screen and (max-width: 1250px) {
    .video {
        width: 100%;
    }
}

/* --- Tooltip --- */

.tooltip-inner {
    max-width: 600px !important;
}

/* --- Alert --- */

.container .alert {
    position: fixed;
    top: 50px;
    z-index: 100;
}

/* --- Fr -> LSFB --- */

a.switch_button {
    text-decoration: none;
    color: #01b1af;
    margin-right: 20px;
    font-size: larger;
    font-weight: bold;
}

    a.switch_button:hover {
        color: #555
    }

#signs {
     font-family: Arial, Helvetica, sans-serif;
     border-collapse: collapse;
     width: 100%;
}


#signs td, #signs th {
  border: 1px solid #ddd;
  padding: 8px;
}

#signs tr:nth-child(even){background-color: #f2f2f2;}

#signs tr:hover {background-color: #ddd;}

.pointer_custor {
    cursor: pointer
}

#signs th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #01b1af;
  color: white;
}

.signs_table {
    margin-top: 50px
}

.examples {
    margin-top: 50px
}

.searchButton {
    margin-left: 10px
}

.examples .example .right-panel {
    margin-left: 50px
}

.examples .example {
    padding-bottom: 50px;
    margin-bottom: 50px;
}

figcaption {
    background-color: #01b1af;
    color: #fff;
    font: italic bold large sans-serif;
    padding: 5px;
    text-align: center;
}

.scrollTopBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #01b1af;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
}

.scrollTopBtn:hover {
    background-color: #555;
}

.tooltips {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltips .tooltiptext {
    visibility: hidden;
    width: 510px;
    background-color: darkslategray;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltips:hover .tooltiptext {
    visibility: visible;
}

.tooltip_img {
    width: 500px
}

.big_gif {
    width: 500px
}

.small_gif {
    width: 150px;
    height: 100px;
}

.button-37 {
    background-color: lightgrey;
    border: 1px solid grey;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    outline: 0;
    padding: 7px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-37:hover {
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
    transform: translateY(-2px);
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #01b1af;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    display: inline-block;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


